import { useState } from "react"

import { FormattedMessage, useIntl } from "react-intl"
import { graphql, useFragment } from "react-relay"

import { BankAccount } from "src/components/BankAccount/BankAccount"
import { BankAccountAddOrEditPopup } from "src/components/BankAccount/BankAccountAddOrEditPopup"
import { useCheckGate } from "src/Gate"
import { GlowTooltip } from "src/glow"
import { GlowButton } from "src/glow/GlowButton"
import { GlowCard } from "src/glow/GlowCard"
import { GlowCheckbox } from "src/glow/GlowCheckbox"
import { GlowFlexbox } from "src/glow/GlowFlexbox"
import { GlowIcon } from "src/glow/GlowIcon"
import { GlowLink } from "src/glow/GlowLink"
import { GlowText } from "src/glow/GlowText"
import { usePopup } from "src/hooks/modal"
import OnboardingEmilyChatBubble from "src/pages/Onboarding/components/OnboardingEmilyChatBubble"
import useTracking from "src/tracking/useTracking"

import { BankAccountDetailsCard_bankAccount$key } from "./__generated__/BankAccountDetailsCard_bankAccount.graphql"
import { BankAccountDetailsCard_household$key } from "./__generated__/BankAccountDetailsCard_household.graphql"

export const BankAccountDetailsCard = (props: {
  household: BankAccountDetailsCard_household$key
  onBack?: () => void
  onSkipped?: () => void
  onContinue?: () => void
  hideCashRewardsCheckbox?: boolean
}) => {
  const household = useFragment(
    graphql`
      fragment BankAccountDetailsCard_household on Household {
        rewardsBankAccount {
          id
          ...BankAccountDetailsCard_bankAccount
        }
      }
    `,
    props.household,
  )

  const intl = useIntl()

  const track = useTracking()

  const checkGate = useCheckGate()
  const newBankDetailsUi = checkGate("bank_details_step_improvements")

  const showSkip =
    checkGate("skip_service_level_bank_details_steps") &&
    (props.onSkipped || props.onContinue)

  const [sendCash, setSendCash] = useState(true)

  return (
    <GlowFlexbox
      direction="column"
      gap="6"
      margin={{
        sm: "4",
        md: {
          x: "4",
          top: "6",
          bottom: "4",
        },
      }}
    >
      <GlowCard>
        <GlowFlexbox direction="column" gap="6">
          <GlowText size="base" textAlign="center" fontWeight="medium">
            {newBankDetailsUi ? (
              <FormattedMessage
                id="onboarding.page.bankDetails.accountDetailsAddition.header.improved"
                defaultMessage="Add bank account details to your wallet so we can set up new providers for you and send you cash rewards."
              />
            ) : (
              <FormattedMessage
                id="onboarding.page.bankDetails.accountDetailsAddition.header"
                defaultMessage="Add details of a bank account that's registered with your home address so we can set up new providers for you."
              />
            )}
          </GlowText>
          <EditableBankAccountOrEmpty
            bankAccount={household.rewardsBankAccount ?? null}
          />

          {props.hideCashRewardsCheckbox ? null : (
            <GlowFlexbox gap="1">
              <GlowCheckbox
                isChecked={sendCash}
                isDisabled={!household.rewardsBankAccount}
                label={intl.formatMessage({
                  id: "onboarding.page.bankDetails.accountDetailsAddition.sendCash",
                  defaultMessage:
                    "Send any cash rewards I earn to this account",
                })}
                labelFontSize="sm"
                labelClassName="font-medium"
                onChange={(checked) => {
                  if (checked && household.rewardsBankAccount) {
                    track([
                      "BankAccount",
                      "CashRewardsTurnedOn",
                      { bankAccountId: household.rewardsBankAccount.id },
                    ])
                  } else if (household.rewardsBankAccount) {
                    track([
                      "BankAccount",
                      "CashRewardsTurnedOff",
                      { bankAccountId: household.rewardsBankAccount.id },
                    ])
                  }

                  setSendCash(checked)
                }}
              />

              {newBankDetailsUi && (
                <GlowTooltip
                  handle={
                    <GlowIcon
                      name="information_circle_bold"
                      className="h-5 w-5 text-gray-300"
                    />
                  }
                >
                  <GlowText textAlign="center">
                    <FormattedMessage
                      id="onboarding.page.bankDetails.accountDetailsAddition.sendCash.tooltip"
                      defaultMessage="Sometimes providers pay us a commission for switching our members to new deals. We pass these to our members in the form of cash rewards. <a>Learn more.</a>"
                      values={{
                        br: <br />,
                        a: (chunk) => (
                          <GlowLink
                            size="xs"
                            className="!inline"
                            label={chunk}
                            href={intl.formatMessage({
                              id: "onboarding.page.bankDetails.accountDetailsAddition.sendCash.tooltip.url",
                              defaultMessage: "https://www.nous.co/faqs",
                            })}
                          />
                        ),
                      }}
                    />
                  </GlowText>
                </GlowTooltip>
              )}
            </GlowFlexbox>
          )}

          {household.rewardsBankAccount && props.onContinue && (
            <GlowButton
              variant="primary"
              label={intl.formatMessage({
                id: "onboarding.page.bankDetails.accountDetailsAddition.nextStepButton",
                defaultMessage: "Continue",
              })}
              onClick={() => {
                track(["BankAccount", "DetailsEntryConfirmed"])
                props.onContinue?.()
              }}
            />
          )}

          <AccordionWarning />

          {showSkip && (
            <GlowLink
              label={intl.formatMessage({
                id: "onboarding.page.bankDetails.accountDetailsAddition.skipLink",
                defaultMessage: "I'll do this later",
              })}
              onClick={() => {
                track(["BankAccount", "DetailsEntrySkipped"])
                props.onSkipped?.() ?? props.onContinue?.()
              }}
            />
          )}
        </GlowFlexbox>
      </GlowCard>
    </GlowFlexbox>
  )
}

const EditableBankAccountOrEmpty = (props: {
  bankAccount: BankAccountDetailsCard_bankAccount$key | null
}) => {
  const bankAccountPopup = usePopup()

  const intl = useIntl()
  const track = useTracking()

  const bankAccount = useFragment(
    graphql`
      fragment BankAccountDetailsCard_bankAccount on BankAccount {
        ...BankAccountAddOrEditPopup_bankAccount
        ...BankAccount
      }
    `,
    props.bankAccount,
  )

  return (
    <>
      <GlowCard colour="transparent">
        {!bankAccount && (
          <GlowFlexbox
            direction="column"
            gap="3"
            alignItems="center"
            className="relative"
          >
            <GlowIcon name="money_wallet_bold" />
            <GlowText size="sm" textAlign="center">
              <FormattedMessage
                id="onboarding.page.bankDetails.accountDetailsAddition.addText"
                defaultMessage="Add a bank account to your wallet"
              />
            </GlowText>
            <GlowButton
              variant="primary"
              size="sm"
              label={intl.formatMessage({
                id: "onboarding.page.bankDetails.accountDetailsAddition.addButton",
                defaultMessage: "Add account details",
              })}
              iconName="add_circle_bold"
              iconPosition="left"
              className="w-full"
              onClick={() => {
                track(["BankAccount", "DetailsEntryStarted", { mode: "Add" }])
                bankAccountPopup.open()
              }}
            />
            <GlowIcon
              name="lock_shield_bold"
              className="absolute -right-2 -top-2 h-3 w-3 text-gray-300"
            />
          </GlowFlexbox>
        )}
        {bankAccount && (
          <BankAccount
            bankAccount={bankAccount}
            onEdit={() => {
              track(["BankAccount", "DetailsEntryStarted", { mode: "Edit" }])
              bankAccountPopup.open()
            }}
          />
        )}
      </GlowCard>

      <BankAccountAddOrEditPopup
        bankAccount={bankAccount ?? null}
        isOpen={bankAccountPopup.isOpen}
        onClose={bankAccountPopup.close}
        onAdded={() => {
          track(["BankAccount", "DetailsEntrySaved", { mode: "Add" }])
          bankAccountPopup.close()
        }}
        onEdited={() => {
          track(["BankAccount", "DetailsEntrySaved", { mode: "Edit" }])
          bankAccountPopup.close()
        }}
      />
    </>
  )
}

const AccordionWarning = () => {
  const checkGate = useCheckGate()
  const newBankDetailsUi = checkGate("bank_details_step_improvements")

  if (newBankDetailsUi) {
    return (
      <OnboardingEmilyChatBubble>
        <GlowText isDisplay>
          <FormattedMessage
            id="onboarding.page.bankDetails.accountDetailsAddition.emilyBubble"
            defaultMessage="You’re on a free plan. We’ll never charge you for anything without your explicit approval."
          />
        </GlowText>
      </OnboardingEmilyChatBubble>
    )
  }
  return (
    <GlowFlexbox
      direction="row"
      padding="4"
      gap="3"
      alignItems="center"
      className="rounded-lg bg-gray-100"
    >
      <GlowIcon name="alert_circle_regular" className="h-5 w-5 shrink-0" />
      <GlowText size="xs">
        <FormattedMessage
          id="onboarding.page.bankDetails.accountDetailsAddition.warning"
          defaultMessage="<strong>Please note:</strong> You're on the free tier with Nous and we'll never charge you without your explicit approval. We'll only use these details when we need to set up new providers or send you cash rewards."
          values={{
            strong: (chunks) => <strong>{chunks}</strong>,
          }}
        />
      </GlowText>
    </GlowFlexbox>
  )
}
