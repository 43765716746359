import React, { useState } from "react"

import clsx from "clsx"
import { FormattedMessage, useIntl } from "react-intl"
import { graphql, useFragment, useMutation } from "react-relay"

import { ServiceLevelSelectBenefits } from "src/components/ServiceLevel/ServiceLevelSelectBenefits"
import { ServiceLevelSelectButtons } from "src/components/ServiceLevel/ServiceLevelSelectButtons"
import { ServiceLevelSelectDescriptionHeader } from "src/components/ServiceLevel/ServiceLevelSelectDescriptionHeader"
import { ServiceLevelSelectDescriptionParagraph } from "src/components/ServiceLevel/ServiceLevelSelectDescriptionParagraph"
import { notify } from "src/components/ToastNotification/ToastNotification"
import {
  GlowBadge,
  GlowButton,
  GlowCard,
  GlowFlexbox,
  GlowHorizontalDivider,
  GlowIcon,
  GlowLink,
  GlowText,
} from "src/glow"
import { usePopup } from "src/hooks/modal"
import useToggle from "src/hooks/useToggle"
import { CreateServiceTypeManagementsInput } from "src/pages/Onboarding/OnboardingServiceLevelPage/hooks/__generated__/useCreateServiceTypeManagements_createServiceTypeManagementsMutation.graphql"
import {
  capitalize,
  CheckedRelayEnum,
  handleFutureValueOnRelayEnumOrNull,
  serviceLevelNameMap,
} from "src/utils"

import {
  NousServiceLevel,
  SettingsPageSavingsMode_household$key,
} from "./__generated__/SettingsPageSavingsMode_household.graphql"
import { SettingsPageSavingsModeCreateServiceTypeManagementsMutation } from "./__generated__/SettingsPageSavingsModeCreateServiceTypeManagementsMutation.graphql"
import { SettingsPageSavingsModeMutation } from "./__generated__/SettingsPageSavingsModeMutation.graphql"
import { ChangeServiceLevelPopup } from "./components/ChangeServiceLevelPopUp"
import { NudgeManualModePopUp } from "./components/NudgeManualModePopUp"
import { ServiceManagementToggles } from "./components/ServiceManagementToggles"
import { ToggleAlert } from "./components/ToggleAlert"
import {
  serviceLevelChangedSuccess,
  serviceLevelChangedFailure,
  serviceManagementsChangedSuccess,
  serviceManagementsChangedFailure,
} from "./content"

export const SettingsPageSavingsMode = (props: {
  household: SettingsPageSavingsMode_household$key
}) => {
  const household = useFragment(
    graphql`
      fragment SettingsPageSavingsMode_household on Household {
        id
        serviceLevel
        serviceManagements {
          __typename
          enabled
        }
        ...NudgeManualModePopUp_household
      }
    `,
    props.household,
  )

  const intl = useIntl()

  const currentServiceLevel = handleFutureValueOnRelayEnumOrNull(
    household.serviceLevel,
    null,
  )

  const [selectedServiceLevel, setSelectedServiceLevel] =
    React.useState<CheckedRelayEnum<NousServiceLevel> | null>(
      currentServiceLevel,
    )

  const [
    updateServiceLevelAndServiceManagements,
    updatingServiceLevelAndServiceManagements,
  ] = useMutation<SettingsPageSavingsModeMutation>(graphql`
    mutation SettingsPageSavingsModeMutation(
      $id: ID!
      $serviceLeveL: NousServiceLevel!
      $managements: CreateServiceTypeManagementsInput!
    ) {
      updateHousehold(id: $id, input: { serviceLevel: $serviceLeveL }) {
        household {
          __typename
        }
      }
      createServiceTypeManagements(input: $managements) {
        household {
          ...OnboardingServiceLevelPageChoiceConfirmationStep_household
          ...DashboardPageQuests_household
          ...QuestsPageContent_household
          ...QuestPageContent_household
          ...SettingsPageOverview_household
          ...SettingsPageSavingsMode_household
          serviceManagements {
            __typename
            enabled
          }
        }
      }
    }
  `)

  const [updateOnlyServiceManagaments, updatingOnlyServiceManagaments] =
    useMutation<SettingsPageSavingsModeCreateServiceTypeManagementsMutation>(
      graphql`
        mutation SettingsPageSavingsModeCreateServiceTypeManagementsMutation(
          $input: CreateServiceTypeManagementsInput!
        ) {
          createServiceTypeManagements(input: $input) {
            household {
              ...OnboardingServiceLevelPageChoiceConfirmationStep_household
              ...DashboardPageQuests_household
              ...QuestsPageContent_household
              ...QuestPageContent_household
              ...SettingsPageOverview_household
              ...SettingsPageSavingsMode_household
            }
          }
        }
      `,
    )

  const changeServiceLevelPopup = usePopup()
  const nudgeManualModePopup = usePopup()

  const updating =
    updatingServiceLevelAndServiceManagements || updatingOnlyServiceManagaments

  const currentServiceTypeManagementEnablement = React.useMemo(
    () => ({
      broadband: household.serviceManagements.some(
        (management) =>
          management.__typename === "BroadbandManagement" && management.enabled,
      ),
      energy: household.serviceManagements.some(
        (management) =>
          management.__typename === "EnergyManagement" && management.enabled,
      ),
      mobile: household.serviceManagements.some(
        (management) =>
          management.__typename === "MobileManagement" && management.enabled,
      ),

      mortgage: household.serviceManagements.some(
        (management) =>
          management.__typename === "MortgageManagement" && management.enabled,
      ),
    }),
    [household.serviceManagements],
  )

  const [serviceTypeManagementEnablement, setServiceTypeManagementEnablement] =
    React.useState<Omit<CreateServiceTypeManagementsInput, "context">>(
      currentServiceTypeManagementEnablement,
    )

  const resetServiceTypeManagementEnablement = React.useCallback(() => {
    setServiceTypeManagementEnablement(currentServiceTypeManagementEnablement)
  }, [currentServiceTypeManagementEnablement])

  const numberOfManagedServices = household.serviceManagements.filter(
    (management) => management.enabled,
  ).length

  const onSuccessfulServiceLevelChange = React.useCallback(
    (customServiceLevel?: CheckedRelayEnum<NousServiceLevel>) => {
      changeServiceLevelPopup.close()
      nudgeManualModePopup.close()

      customServiceLevel && setSelectedServiceLevel(customServiceLevel)

      if (selectedServiceLevel) {
        notify.success(
          intl.formatMessage(serviceLevelChangedSuccess, {
            serviceLevel: capitalize(
              intl.formatMessage(
                serviceLevelNameMap[customServiceLevel ?? selectedServiceLevel],
              ),
            ),
          }),
        )
      }
    },
    [changeServiceLevelPopup, intl, selectedServiceLevel, nudgeManualModePopup],
  )

  const onFailedServiceLevelChange = React.useCallback(
    (customServiceLevel?: CheckedRelayEnum<NousServiceLevel>) => {
      changeServiceLevelPopup.close()
      nudgeManualModePopup.close()

      if (selectedServiceLevel) {
        notify.error(
          intl.formatMessage(serviceLevelChangedFailure, {
            serviceLevel: capitalize(
              intl.formatMessage(
                serviceLevelNameMap[customServiceLevel ?? selectedServiceLevel],
              ),
            ),
          }),
        )
      }
    },
    [changeServiceLevelPopup, intl, selectedServiceLevel, nudgeManualModePopup],
  )

  const updateBothServiceLevelAndServiceManagements = React.useCallback(
    (customServiceLevel?: CheckedRelayEnum<NousServiceLevel>) => {
      const serviceLevel = customServiceLevel || selectedServiceLevel

      serviceLevel &&
        updateServiceLevelAndServiceManagements({
          variables: {
            id: household.id,
            serviceLeveL: serviceLevel,
            managements: {
              ...(serviceLevel === "DO_EVERYTHING_MYSELF"
                ? {
                    broadband: false,
                    energy: false,
                    mobile: false,
                    mortgage: false,
                  }
                : serviceTypeManagementEnablement),
              context: "SETTINGS_PAGE",
            },
          },
          onCompleted: (data, errors) => {
            if (data.createServiceTypeManagements?.household) {
              setServiceTypeManagementEnablement({
                broadband:
                  data.createServiceTypeManagements.household.serviceManagements.some(
                    (management) =>
                      management.__typename === "BroadbandManagement" &&
                      management.enabled,
                  ),
                energy:
                  data.createServiceTypeManagements.household.serviceManagements.some(
                    (management) =>
                      management.__typename === "EnergyManagement" &&
                      management.enabled,
                  ),
                mobile:
                  data.createServiceTypeManagements.household.serviceManagements.some(
                    (management) =>
                      management.__typename === "MobileManagement" &&
                      management.enabled,
                  ),

                mortgage:
                  data.createServiceTypeManagements.household.serviceManagements.some(
                    (management) =>
                      management.__typename === "MortgageManagement" &&
                      management.enabled,
                  ),
              })
            }

            if (!errors) {
              onSuccessfulServiceLevelChange(serviceLevel)
            } else {
              onFailedServiceLevelChange(serviceLevel)
            }
          },
          onError: () => {
            onFailedServiceLevelChange(serviceLevel)
          },
        })
    },
    [
      household.id,
      onFailedServiceLevelChange,
      onSuccessfulServiceLevelChange,
      selectedServiceLevel,
      serviceTypeManagementEnablement,
      updateServiceLevelAndServiceManagements,
    ],
  )

  const onSuccessfulServiceManagementsChange = () => {
    notify.success(intl.formatMessage(serviceManagementsChangedSuccess))
  }

  const onFailedServiceManagementsChange = () => {
    notify.error(intl.formatMessage(serviceManagementsChangedFailure))
  }

  const information = useToggle(false)

  const isDisabled =
    !currentServiceLevel ||
    currentServiceLevel === "DO_EVERYTHING_MYSELF" ||
    updating

  const [numberOfTogglesOn, setnumberOfTogglesOn] = useState(
    Object.values(serviceTypeManagementEnablement).filter(Boolean).length,
  )

  React.useEffect(() => {
    setnumberOfTogglesOn(
      Object.values(serviceTypeManagementEnablement).filter(Boolean).length,
    )
  }, [serviceTypeManagementEnablement])

  const showAlert = Boolean(
    selectedServiceLevel &&
      ((currentServiceLevel !== "DO_EVERYTHING_MYSELF" &&
        numberOfTogglesOn === 0) ||
        (currentServiceLevel === "DO_EVERYTHING_MYSELF" &&
          numberOfTogglesOn > 0)),
  )

  return (
    <>
      <GlowFlexbox direction="column" gap="4">
        {currentServiceLevel && (
          <GlowCard>
            <GlowFlexbox justifyContent="space-between" alignItems="center">
              <GlowText fontWeight="bold">
                <FormattedMessage
                  id="page.settings.view.savingsMode.currentServiceLevel"
                  defaultMessage="Current savings mode"
                />
              </GlowText>

              <GlowBadge
                label={intl.formatMessage(
                  serviceLevelNameMap[currentServiceLevel],
                )}
                size="sm"
                variant="bold"
              />
            </GlowFlexbox>
          </GlowCard>
        )}

        <GlowCard>
          <GlowFlexbox direction="column">
            <div className="mb-6">
              <ServiceLevelSelectButtons
                selectedServiceLevel={selectedServiceLevel}
                onSelect={(serviceLevel) => {
                  setSelectedServiceLevel(serviceLevel)
                  information.setTrue()
                }}
                variant="default"
              />
            </div>

            {selectedServiceLevel && (
              <ServiceLevelSelectDescriptionHeader
                selectedServiceLevel={selectedServiceLevel}
              />
            )}

            {!selectedServiceLevel && (
              <GlowText
                size={{ sm: "base", md: "lg" }}
                fontWeight="medium"
                isDisplay
                textAlign="center"
              >
                <FormattedMessage
                  id="page.settings.view.savingsMode.selectAServiceLevel"
                  defaultMessage="Please select a savings mode"
                />
              </GlowText>
            )}

            {selectedServiceLevel && information.value && (
              <GlowFlexbox
                direction="column"
                justifyContent="center"
                gap="6"
                margin={{
                  top: "4",
                }}
              >
                <ServiceLevelSelectDescriptionParagraph
                  selectedServiceLevel={selectedServiceLevel}
                />
                <ServiceLevelSelectBenefits
                  selectedServiceLevel={selectedServiceLevel}
                />
                {selectedServiceLevel === currentServiceLevel && (
                  <GlowText
                    fontStyle="italic"
                    fontWeight="bold"
                    size="sm"
                    textAlign="center"
                  >
                    <FormattedMessage
                      id="page.settings.view.savingsMode.information.currentMode"
                      defaultMessage="You're currently on this mode."
                    />
                  </GlowText>
                )}
                {selectedServiceLevel !== currentServiceLevel && (
                  <GlowButton
                    variant="primary"
                    size="sm"
                    className="w-full"
                    label={
                      currentServiceLevel
                        ? intl.formatMessage(
                            {
                              id: "page.settings.view.savingsMode.information.action",
                              defaultMessage:
                                "-> Switch to {serviceLevel} mode",
                            },
                            {
                              serviceLevel: capitalize(
                                intl.formatMessage(
                                  serviceLevelNameMap[selectedServiceLevel],
                                ),
                              ),
                            },
                          )
                        : intl.formatMessage(
                            {
                              id: "page.settings.view.savingsMode.information.action.first",
                              defaultMessage: "Select {serviceLevel} mode",
                            },
                            {
                              serviceLevel: capitalize(
                                intl.formatMessage(
                                  serviceLevelNameMap[selectedServiceLevel],
                                ),
                              ),
                            },
                          )
                    }
                    onClick={() => {
                      if (
                        [null, "DO_EVERYTHING_MYSELF"].includes(
                          currentServiceLevel,
                        )
                      ) {
                        setServiceTypeManagementEnablement({
                          broadband: true,
                          energy: true,
                          mobile: true,
                          mortgage: true,
                        })
                      }
                      if (selectedServiceLevel === "DO_EVERYTHING_MYSELF") {
                        nudgeManualModePopup.open()
                        return
                      }
                      changeServiceLevelPopup.open()
                    }}
                  />
                )}
              </GlowFlexbox>
            )}

            {selectedServiceLevel && (
              <>
                <GlowHorizontalDivider
                  className={clsx(information.value ? "mt-4" : "mt-6")}
                />

                <GlowLink
                  label={
                    information.value
                      ? intl.formatMessage(
                          {
                            id: "page.settings.view.savingsMode.information.less",
                            defaultMessage: "Less about {serviceLevel} mode",
                          },
                          {
                            serviceLevel: capitalize(
                              intl.formatMessage(
                                serviceLevelNameMap[selectedServiceLevel],
                              ),
                            ),
                          },
                        )
                      : intl.formatMessage(
                          {
                            id: "page.settings.view.savingsMode.information.more",
                            defaultMessage: "More about {serviceLevel} mode",
                          },
                          {
                            serviceLevel: capitalize(
                              intl.formatMessage(
                                serviceLevelNameMap[selectedServiceLevel],
                              ),
                            ),
                          },
                        )
                  }
                  className="group"
                  icon={
                    <GlowIcon
                      name="arrow_down_1_regular"
                      className={clsx(
                        "text-gray-500/64 h-5 w-5 transform transition-transform duration-200 group-hover:text-gray-500",
                        information.value && "rotate-180",
                      )}
                    />
                  }
                  margin={{
                    top: "4",
                  }}
                  onClick={information.toggle}
                />
              </>
            )}
          </GlowFlexbox>
        </GlowCard>

        <GlowCard>
          <GlowFlexbox direction="column" gap="6">
            <GlowFlexbox direction="column" gap="2">
              <GlowText fontWeight="bold">
                <FormattedMessage
                  id="page.settings.view.savingsMode.updateManagedServicesTitle"
                  defaultMessage="Managed services"
                />
              </GlowText>

              {[null, "DO_EVERYTHING_MYSELF"].includes(currentServiceLevel) && (
                <GlowFlexbox gap="2">
                  <GlowIcon
                    name="alert_circle_bold"
                    className="h-5 w-auto text-gray-300"
                  />
                  <GlowText>
                    <FormattedMessage
                      id="page.settings.view.savingsMode.updateManagedServicesManualAlert"
                      defaultMessage="We won't secure savings for you in Manual mode"
                    />
                  </GlowText>
                </GlowFlexbox>
              )}

              {currentServiceLevel !== null &&
                currentServiceLevel !== "DO_EVERYTHING_MYSELF" && (
                  <GlowText>
                    {intl.formatMessage<React.ReactNode>(
                      {
                        id: "page.settings.view.savingsMode.updateManagedServicesSubtitle",
                        defaultMessage:
                          "We're securing savings for <bold>{numberOfManagedServices}</bold> services",
                      },
                      {
                        numberOfManagedServices: numberOfManagedServices,
                        bold: (chunks) => (
                          <GlowText fontWeight="bold">{chunks}</GlowText>
                        ),
                      },
                    )}
                  </GlowText>
                )}
            </GlowFlexbox>

            <ServiceManagementToggles
              serviceTypeManagementEnablement={
                changeServiceLevelPopup.isOpen
                  ? currentServiceTypeManagementEnablement
                  : serviceTypeManagementEnablement
              }
              isDisabled={isDisabled}
              setServiceTypeManagementEnablement={
                setServiceTypeManagementEnablement
              }
            />

            {showAlert && currentServiceLevel && (
              <ToggleAlert serviceLevel={currentServiceLevel} />
            )}

            <GlowButton
              variant="primary"
              size="sm"
              label={intl.formatMessage({
                id: "page.settings.view.savingsMode.updateManagedServicesButton",
                defaultMessage: "Save",
              })}
              isDisabled={isDisabled || showAlert}
              isLoading={updating}
              onClick={() => {
                if (numberOfTogglesOn === 0) {
                  return
                }

                updateOnlyServiceManagaments({
                  variables: {
                    input: {
                      ...serviceTypeManagementEnablement,
                      context: "SETTINGS_PAGE",
                    },
                  },
                  onCompleted: (_, errors) => {
                    if (!errors) {
                      onSuccessfulServiceManagementsChange()
                    } else {
                      onFailedServiceManagementsChange()
                    }
                  },
                  onError: () => {
                    onFailedServiceManagementsChange()
                  },
                })
              }}
            />
          </GlowFlexbox>
        </GlowCard>
      </GlowFlexbox>

      {selectedServiceLevel && (
        <ChangeServiceLevelPopup
          isOpen={changeServiceLevelPopup.isOpen}
          onConfirm={() => {
            updateBothServiceLevelAndServiceManagements()
          }}
          onCancel={() => {
            changeServiceLevelPopup.close()
            resetServiceTypeManagementEnablement()
          }}
          onClose={() => {
            changeServiceLevelPopup.close()
            resetServiceTypeManagementEnablement()
          }}
          from={currentServiceLevel}
          to={selectedServiceLevel}
          updating={updating}
          serviceTypeManagementEnablement={serviceTypeManagementEnablement}
          setServiceTypeManagementEnablement={
            setServiceTypeManagementEnablement
          }
          areAllTogglesOff={numberOfTogglesOn === 0}
        />
      )}

      {selectedServiceLevel && (
        <NudgeManualModePopUp
          household={household}
          isOpen={nudgeManualModePopup.isOpen}
          onConfirm={(serviceLevel) => {
            updateBothServiceLevelAndServiceManagements(serviceLevel)
          }}
          onCancel={() => {
            nudgeManualModePopup.close()
            resetServiceTypeManagementEnablement()
          }}
          onClose={() => {
            nudgeManualModePopup.close()
            resetServiceTypeManagementEnablement()
          }}
          from={currentServiceLevel}
          updating={updating}
        />
      )}
    </>
  )
}
