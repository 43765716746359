import clsx from "clsx"
import { useIntl } from "react-intl"

import { GlowFlexbox, GlowIcon, GlowText } from "src/glow"
import { NousServiceLevel } from "src/hooks/__generated__/useRedirectToWaitlist_household.graphql"
import { CheckedRelayEnum, serviceLevelBenefitsMap } from "src/utils"

export const ServiceLevelSelectBenefits = ({
  selectedServiceLevel,
  variant = "default",
}: {
  selectedServiceLevel: CheckedRelayEnum<NousServiceLevel>
  variant?: "default" | "snappy"
}) => {
  const intl = useIntl()

  return (
    <GlowFlexbox
      direction={variant === "snappy" ? "row" : { sm: "column", md: "row" }}
      gap="6"
      alignItems="start"
    >
      {serviceLevelBenefitsMap[selectedServiceLevel].map((benefit, index) => (
        <GlowFlexbox
          direction={
            variant === "snappy" ? "column" : { sm: "row", md: "column" }
          }
          alignItems="center"
          gap="3"
          key={index}
          className={clsx(
            variant === "snappy" && "w-1/3",
            variant === "default" && "w-full md:w-1/3",
          )}
        >
          <GlowIcon
            name={benefit.icon}
            className={clsx(
              "h-6 w-6 shrink-0",
              benefit.missed && "text-angsty-red-400",
            )}
          />
          <GlowText
            size="sm"
            textAlign={
              variant === "snappy"
                ? "center"
                : {
                    sm: "left",
                    md: "center",
                  }
            }
          >
            {intl.formatMessage(benefit.text)}
          </GlowText>
        </GlowFlexbox>
      ))}
    </GlowFlexbox>
  )
}
