import clsx from "clsx"

import bcorp from "src/assets/bcorp.png"
import bcorp2x from "src/assets/bcorp@2x.png"
import bcorp3x from "src/assets/bcorp@3x.png"
import trustpilotMicroStars from "src/assets/trustpilotMicroStars.png"
import trustpilotMicroStars2x from "src/assets/trustpilotMicroStars@2x.png"
import trustpilotMicroStars3x from "src/assets/trustpilotMicroStars@3x.png"
import { GlowCard, GlowFlexbox } from "src/glow"

export const NousCertifications = ({ className }: { className?: string }) => {
  return (
    <GlowCard colour="transparent" className={clsx("space-y-4", className)}>
      <GlowFlexbox gap="12" justifyContent="center" alignItems="center">
        <img
          srcSet={`${trustpilotMicroStars}, ${trustpilotMicroStars2x} 2x, ${trustpilotMicroStars3x} 3x`}
          alt=""
          className="w-[75px]"
        />
        <img
          srcSet={`${bcorp}, ${bcorp2x} 2x, ${bcorp3x} 3x`}
          alt=""
          className="w-[30px]"
        />
      </GlowFlexbox>
    </GlowCard>
  )
}
