import { useIntl } from "react-intl"
import { graphql, useFragment } from "react-relay"

import { BankAccountDetailsCard } from "src/components/BankAccount/BankAccountDetailsCard"

import { SettingsPageSubPageBankAccounts_household$key } from "./__generated__/SettingsPageSubPageBankAccounts_household.graphql"
import { SettingsPageSubPageLayout } from "./SettingsPageSubPageLayout"

import { titleMap } from "../utils"

export const SettingsPageSubPageBankAccounts = (props: {
  household: SettingsPageSubPageBankAccounts_household$key
  onBackToOverview: () => void
}) => {
  const intl = useIntl()

  const household = useFragment(
    graphql`
      fragment SettingsPageSubPageBankAccounts_household on Household {
        ...BankAccountDetailsCard_household
      }
    `,
    props.household,
  )

  return (
    <SettingsPageSubPageLayout
      title={intl.formatMessage(titleMap["bank-accounts"])}
      onBackToOverview={props.onBackToOverview}
    >
      <BankAccountDetailsCard household={household} hideCashRewardsCheckbox />
    </SettingsPageSubPageLayout>
  )
}
