import clsx from "clsx"

import { GlowFlexbox, GlowIcon, GlowText, GlowToggle } from "src/glow"
import useTracking from "src/tracking/useTracking"
import {
  categoryManagementIconMap,
  capitalize,
  SupportedManagement,
  categoryManagementNameMap,
} from "src/utils"

import { CreateServiceTypeManagementsInput } from "../__generated__/SettingsPageSavingsModeCreateServiceTypeManagementsMutation.graphql"

type ServiceManagementTogglesProps = {
  serviceTypeManagementEnablement: Omit<
    CreateServiceTypeManagementsInput,
    "context"
  >
  isDisabled: boolean
  setServiceTypeManagementEnablement: (
    value: React.SetStateAction<
      Omit<CreateServiceTypeManagementsInput, "context">
    >,
  ) => void
}

export function ServiceManagementToggles(props: ServiceManagementTogglesProps) {
  const track = useTracking()

  return (
    <GlowFlexbox direction="column" gap="4" className="w-full">
      {Object.entries(props.serviceTypeManagementEnablement).map(
        ([key, value]) => (
          <GlowFlexbox
            key={key}
            justifyContent="space-between"
            alignItems="center"
          >
            <GlowFlexbox gap="3" alignItems="center" justifyContent="start">
              <GlowFlexbox
                as="span"
                className="relative grid h-10 w-10 place-items-center rounded-full bg-gray-50"
              >
                <GlowIcon
                  name={
                    categoryManagementIconMap[
                      `${capitalize(key)}Management` as SupportedManagement
                    ]
                  }
                  className="h-5 w-5 text-gray-500"
                />

                <GlowIcon
                  name="check_circle_1_bold"
                  className={clsx(
                    "absolute right-0 top-0 h-3 w-3",
                    value ? "text-virtuous-green-400" : "text-gray-200",
                  )}
                />
              </GlowFlexbox>

              <GlowText size="sm">
                {
                  categoryManagementNameMap[
                    `${capitalize(key)}Management` as SupportedManagement
                  ]
                }
              </GlowText>
            </GlowFlexbox>

            <GlowToggle
              isDisabled={props.isDisabled}
              onToggle={() => {
                track([
                  "ServiceLineManagement",
                  "Edited",
                  {
                    value: value ? "OFF" : "ON",
                    category:
                      categoryManagementNameMap[
                        `${capitalize(key)}Management` as SupportedManagement
                      ],
                  },
                ])
                props.setServiceTypeManagementEnablement((current) => ({
                  ...current,
                  [key]: !value,
                }))
              }}
              isChecked={value}
            />
          </GlowFlexbox>
        ),
      )}
    </GlowFlexbox>
  )
}
