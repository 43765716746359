import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { z } from "zod"

const searchSchema = z.object({
  fullHeight: z.boolean().optional(),
  popUp: z.boolean().optional(),
})

export const Route = createFileRoute("/typeform/$id")({
  validateSearch: searchSchema,
  component: lazyRouteComponent(
    () => import("src/pages/TypeformEmbed/TypeformEmbed"),
  ),
})
