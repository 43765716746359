/**
 * @generated SignedSource<<2bee81abffe01e122df043605b424bc7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnboardingBankDetailsPageQuery$variables = Record<PropertyKey, never>;
export type OnboardingBankDetailsPageQuery$data = {
  readonly household: {
    readonly isDelegated: boolean | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"OnboardingBankDetailsPageAccountDetailsAdditionStep_household">;
  };
};
export type OnboardingBankDetailsPageQuery = {
  response: OnboardingBankDetailsPageQuery$data;
  variables: OnboardingBankDetailsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDelegated",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OnboardingBankDetailsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OnboardingBankDetailsPageAccountDetailsAdditionStep_household"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OnboardingBankDetailsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccount",
            "kind": "LinkedField",
            "name": "rewardsBankAccount",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sortCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd76b002d223784622a35e3c2bb1b322",
    "id": null,
    "metadata": {},
    "name": "OnboardingBankDetailsPageQuery",
    "operationKind": "query",
    "text": "query OnboardingBankDetailsPageQuery {\n  household {\n    isDelegated\n    ...OnboardingBankDetailsPageAccountDetailsAdditionStep_household\n    id\n  }\n}\n\nfragment BankAccount on BankAccount {\n  title\n  firstName\n  lastName\n  accountNumber\n  sortCode\n}\n\nfragment BankAccountAddOrEditPopup_bankAccount on BankAccount {\n  ...BankAccountDetailsGlowForm_bankAccount\n}\n\nfragment BankAccountDetailsCard_bankAccount on BankAccount {\n  ...BankAccountAddOrEditPopup_bankAccount\n  ...BankAccount\n}\n\nfragment BankAccountDetailsCard_household on Household {\n  rewardsBankAccount {\n    id\n    ...BankAccountDetailsCard_bankAccount\n  }\n}\n\nfragment BankAccountDetailsGlowForm_bankAccount on BankAccount {\n  title\n  firstName\n  lastName\n  sortCode\n  accountNumber\n}\n\nfragment OnboardingBankDetailsPageAccountDetailsAdditionStep_household on Household {\n  ...BankAccountDetailsCard_household\n}\n"
  }
};
})();

(node as any).hash = "03777cab4b884e9c73a343749ba9ad77";

export default node;
