import { ElementType, useContext } from "react"
import React from "react"

import clsx from "clsx"

import {
  GlowResponsiveSpacing,
  GlowResponsiveWrapper,
  glowSpacingToClassNames,
} from "./structure"
import { GlowBreakpoint, breakpointsList, sortObjectKeys } from "./utils"

type GlowFontWeight = "bold" | "normal" | "medium" | "light"
export type GlowTextSize =
  | "6xl"
  | "5xl"
  | "4xl"
  | "3xl"
  | "2xl"
  | "xl"
  | "lg"
  | "base"
  | "sm"
  | "xs"

const glowTextSizeToClassNamesRecord: Record<GlowTextSize, string> = {
  "6xl": "text-6xl",
  "5xl": "text-5xl",
  "4xl": "text-4xl",
  "3xl": "text-3xl",
  "2xl": "text-2xl",
  xl: "text-xl",
  lg: "text-lg",
  base: "text-base",
  sm: "text-sm",
  xs: "text-xs",
}

type GlowTextAlign = "center" | "left" | "right"

const glowTextAlignToClassNamesRecord: Record<GlowTextAlign, string> = {
  center: "text-center",
  left: "text-left",
  right: "text-right",
}

const glowBreakPointSizeToClassNamesRecord: Record<
  GlowBreakpoint,
  Record<GlowTextSize, string>
> = {
  xs: {
    "6xl": "xs:text-6xl",
    "5xl": "xs:text-5xl",
    "4xl": "xs:text-4xl",
    "3xl": "xs:text-3xl",
    "2xl": "xs:text-2xl",
    xl: "xs:text-xl",
    lg: "xs:text-lg",
    base: "xs:text-base",
    sm: "xs:text-sm",
    xs: "xs:text-xs",
  },
  sm: {
    "6xl": "sm:text-6xl",
    "5xl": "sm:text-5xl",
    "4xl": "sm:text-4xl",
    "3xl": "sm:text-3xl",
    "2xl": "sm:text-2xl",
    xl: "sm:text-xl",
    lg: "sm:text-lg",
    base: "sm:text-base",
    sm: "sm:text-sm",
    xs: "sm:text-xs",
  },
  md: {
    "6xl": "md:text-6xl",
    "5xl": "md:text-5xl",
    "4xl": "md:text-4xl",
    "3xl": "md:text-3xl",
    "2xl": "md:text-2xl",
    xl: "md:text-xl",
    lg: "md:text-lg",
    base: "md:text-base",
    sm: "md:text-sm",
    xs: "md:text-xs",
  },
  lg: {
    "6xl": "lg:text-6xl",
    "5xl": "lg:text-5xl",
    "4xl": "lg:text-4xl",
    "3xl": "lg:text-3xl",
    "2xl": "lg:text-2xl",
    xl: "lg:text-xl",
    lg: "lg:text-lg",
    base: "lg:text-base",
    sm: "lg:text-sm",
    xs: "lg:text-xs",
  },
  xl: {
    "6xl": "xl:text-6xl",
    "5xl": "xl:text-5xl",
    "4xl": "xl:text-4xl",
    "3xl": "xl:text-3xl",
    "2xl": "xl:text-2xl",
    xl: "xl:text-xl",
    lg: "xl:text-lg",
    base: "xl:text-base",
    sm: "xl:text-sm",
    xs: "xl:text-xs",
  },
  xxl: {
    "6xl": "xxl:text-6xl",
    "5xl": "xxl:text-5xl",
    "4xl": "xxl:text-4xl",
    "3xl": "xxl:text-3xl",
    "2xl": "xxl:text-2xl",
    xl: "xxl:text-xl",
    lg: "xxl:text-lg",
    base: "xxl:text-base",
    sm: "xxl:text-sm",
    xs: "xxl:text-xs",
  },
}

export function glowTextSizeToClassNames(
  size: GlowResponsiveWrapper<GlowTextSize> | undefined,
) {
  const classNames: string[] = []

  if (typeof size === "undefined") {
    return ["text-base"]
  }

  if (typeof size === "string") {
    return [glowTextSizeToClassNamesRecord[size]]
  }

  const sortedGap = sortObjectKeys<GlowBreakpoint, GlowTextSize>(
    size,
    breakpointsList,
  )
  Object.entries(sortedGap).forEach(([breakpoint, value], index) => {
    classNames.push(
      index === 0
        ? glowTextSizeToClassNamesRecord[value]
        : glowBreakPointSizeToClassNamesRecord[breakpoint as GlowBreakpoint][
            value
          ],
    )
  })

  return classNames
}

export type GlowTextVariant = "secondary" | "primary"

type Element =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "p"
  | "span"
  | "legend"
  | "div"
  | "button"
  | "li"

type GlowTextProps<E extends Element> = {
  as?: E
  children: React.ReactNode
  textAlign?: GlowResponsiveWrapper<GlowTextAlign>
  className?: string
  fontWeight?: GlowFontWeight
  isDisplay?: boolean
  size?: GlowResponsiveWrapper<GlowTextSize>
  margin?: GlowResponsiveSpacing
  padding?: GlowResponsiveSpacing
  variant?: GlowTextVariant
  fontStyle?: "italic"
  textDecoration?: "underline" | "strikethrough"
} & React.ComponentPropsWithoutRef<E> &
  React.ComponentPropsWithoutRef<ElementType>

export const glowFontWeightRecord: Record<GlowFontWeight, string> = {
  bold: "font-bold",
  medium: "font-medium",
  normal: "font-normal",
  light: "font-light",
}

export const isDisplayFontWeightRecord: Record<GlowFontWeight, string> = {
  bold: "font-good-sans-bold",
  medium: "font-good-sans-medium",
  normal: "font-good-sans-regular",
  light: "font-good-sans-light",
}

export function GlowText<E extends Element>({
  as,
  size,
  className,
  margin,
  padding,
  textAlign,
  variant,
  isDisplay,
  fontWeight,
  children,
  fontStyle,
  textDecoration,
  ...props
}: GlowTextProps<E>) {
  const Component = as || "span"
  const { size: fallbackSize } = useGlowTextInheritedStyle()
  return (
    <GlowTextInheritedStyleContext.Provider
      value={{ size: size ?? fallbackSize }}
    >
      <Component
        className={clsx(
          "font-synthesis-0 antialiased",
          variant === "secondary" && "opacity-64",
          fontWeight && glowFontWeightRecord[fontWeight],
          isDisplay && isDisplayFontWeightRecord[fontWeight ?? "normal"],
          ...glowTextSizeToClassNames(size ?? fallbackSize),
          ...glowSpacingToClassNames(padding ?? {}, "p"),
          ...glowSpacingToClassNames(margin ?? {}, "m"),
          ...glowTextAlignToClassNames(textAlign ?? "left"),
          fontStyle === "italic" && "italic",
          textDecoration === "underline" && "underline",
          textDecoration === "strikethrough" && "line-through",
          className,
        )}
        {...props}
      >
        {children}
      </Component>
    </GlowTextInheritedStyleContext.Provider>
  )
}

const GlowTextInheritedStyleContext = React.createContext<{
  size: NonNullable<GlowTextProps<Element>["size"]>
}>({
  size: "base",
})

export function useGlowTextInheritedStyle() {
  return useContext(GlowTextInheritedStyleContext)
}

export function glowTextAlignToClassNames(
  textAlign: GlowResponsiveWrapper<GlowTextAlign> | undefined,
) {
  const classNames: string[] = []

  if (typeof textAlign === "undefined") {
    return ["text-left"]
  }

  if (typeof textAlign === "string") {
    return [glowTextAlignToClassNamesRecord[textAlign]]
  }

  const sortedAligns = sortObjectKeys<GlowBreakpoint, GlowTextAlign>(
    textAlign,
    breakpointsList,
  )
  Object.entries(sortedAligns).forEach(([breakpoint, value], index) => {
    classNames.push(
      index === 0
        ? glowTextAlignToClassNamesRecord[value]
        : `${breakpoint}:${glowTextAlignToClassNamesRecord[value]}`,
    )
  })

  return classNames
}
