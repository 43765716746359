import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import Dinero from "dinero.js"
import { DateTime } from "luxon"
import { UseMutationConfig, graphql, useMutation } from "react-relay"

import useCounter from "src/hooks/useCounter"
import useLastValue from "src/hooks/useLastValue"
import { logger } from "src/logger"
import useTracking from "src/tracking/useTracking"
import {
  filterNullsAndFalse,
  handleFutureValueOnRelayEnum,
  relayMutationToPromise,
} from "src/utils"

import {
  OnboardingOrchestratorMutation,
  UpdateFormAnswerAnswerInput,
} from "./__generated__/OnboardingOrchestratorMutation.graphql"

type Answer = UpdateFormAnswerAnswerInput & {
  key: string
}

type AnswersInput = {
  answers?: Answer[] | Answer
  step?: number
  optimisticUpdater?: UseMutationConfig<OnboardingOrchestratorMutation>["optimisticUpdater"]
}

type OnboardingOrchestratorData = {
  [key: string]: unknown
}

class MetadataTracker {
  _parent?: MetadataTracker
  _metadata: Record<string, OnboardingOrchestratorData> = {}

  constructor(parent?: MetadataTracker) {
    this._parent = parent
  }

  register(data: OnboardingOrchestratorData): () => void {
    const id = Math.random().toString(36).slice(2)
    this._metadata[id] = data
    return () => {
      delete this._metadata[id]
    }
  }

  getMetadata(): OnboardingOrchestratorData {
    const parentMedata = this._parent?.getMetadata() ?? {}
    const currentMetadata = Object.values(this._metadata).reduce(
      (acc, metadata) => ({ ...acc, ...metadata }),
      {},
    )
    return { ...parentMedata, ...currentMetadata }
  }
}

type ContextValue = {
  onNextStep:
    | ((
        props: AnswersInput & {
          data: OnboardingOrchestratorData
          dontEmitStep?: true
        } & OrchestratorEventListenerTrackingProperties,
      ) => void)
    | null
  onPreviousStep: (
    options: {
      step?: number
      data: OnboardingOrchestratorData
    } & OrchestratorEventListenerTrackingProperties,
  ) => void
  title?: React.ReactNode
  numberOfSteps: number
  currentStep: number
  lastStep: number | null
  updateAnswerState?: UpdateAnswersState
  listeners: OnboardingOrchestratorListeners
  metadataTracker: MetadataTracker
}

type UpdateAnswersState =
  | { type: "COMPLETED" }
  | { type: "UPDATING"; promise: Promise<void> }

type OnboardingEvent = { data: OnboardingOrchestratorData }

type OnboardingOrchestratorListeners = {
  onNextStep?: (event: OnboardingEvent) => void
  onPreviousStep?: (event: OnboardingEvent) => void
  onStepRendered?: (event: OnboardingEvent) => void
}

type OrchestratorContext = "Onboarding" | "Wizard" | "Task"
type OnboardingOrchestratorProps = {
  steps: (React.ReactElement | null | false)[]
  orchestratorContext: OrchestratorContext
  title?: React.ReactNode
  onCompleted?: () => void
} & OnboardingOrchestratorListeners

function mergeListeners(
  listeners1: OnboardingOrchestratorListeners = {},
  listeners2: OnboardingOrchestratorListeners = {},
): OnboardingOrchestratorListeners {
  return {
    onNextStep: (event) => {
      listeners1.onNextStep?.(event)
      listeners2.onNextStep?.(event)
    },
    onPreviousStep: (event) => {
      listeners1.onPreviousStep?.(event)
      listeners2.onPreviousStep?.(event)
    },
    onStepRendered: (event) => {
      listeners1.onStepRendered?.(event)
      listeners2.onStepRendered?.(event)
    },
  }
}

function useUpdateAnswers(orchestratorContext: OrchestratorContext) {
  const [updatingStage, setUpdatingState] = useState<UpdateAnswersState>({
    type: "COMPLETED",
  })
  const [runMutation] = useMutation<OnboardingOrchestratorMutation>(graphql`
    mutation OnboardingOrchestratorMutation(
      $answers: [UpdateFormAnswerAnswerInput!]!
    ) {
      updateFormAnswers(answers: $answers) {
        formAnswers {
          ...OnboardingGenericChoiceStep_answer
          ...OnboardingGenericMoneyQuestionStep_answer
          ...OnboardingGenericIntegerQuestionStep_answer
          ...OnboardingGenericMonthStep_answer
          ...OnboardingGenericYesNoQuestionStep_answer
          ...OnboardingProviderStep_answer
          ...OnboardingGenericStringQuestionStep_answer
        }
        household {
          ...OnboardingPageSteps_household
          energyServiceSummary {
            ...WizardFinalStep_serviceSummary
          }
          broadbandServiceSummary {
            ...WizardFinalStep_serviceSummary
          }
          mobileServiceSummaries {
            ...WizardFinalStep_serviceSummary
          }
          mortgageServiceSummary {
            ...WizardFinalStep_serviceSummary
          }
        }
      }
    }
  `)

  const track = useTracking()
  const update = useCallback(
    (
      answers: Answer[],
      options: {
        optimisticUpdater: AnswersInput["optimisticUpdater"]
      },
    ) => {
      answers.forEach((answer) => {
        track([
          "Question",
          "Answered",
          {
            questionContext: orchestratorContext,
            question: answer.key,
            answer:
              answer.stringValue ??
              answer.booleanValue?.toString() ??
              answer.intValue?.toString() ??
              answer.floatValue?.toString() ??
              answer.providerValue ??
              (
                answer.moneyValue &&
                Dinero({
                  amount: answer.moneyValue.amount,
                  currency: handleFutureValueOnRelayEnum(
                    answer.moneyValue.currency,
                    "GBP",
                  ),
                  precision: answer.moneyValue.precision,
                }).toRoundedUnit(0)
              )?.toString() ??
              (answer.dateValue &&
                DateTime.fromISO(answer.dateValue).toISODate()) ??
              null,
          },
        ])
      })
      const promise = relayMutationToPromise(runMutation, {
        variables: {
          answers: answers.map(({ key: _key, ...answer }) => answer),
        },
        optimisticUpdater(store, result) {
          answers.forEach((answer) => {
            const formAnswer = store.get(answer.id)
            if (formAnswer) {
              formAnswer.setValue(answer.stringValue, "stringValue")
              formAnswer.setValue(answer.booleanValue, "booleanValue")
              formAnswer.setValue(answer.intValue, "intValue")
              formAnswer.setValue(answer.floatValue, "floatValue")
              formAnswer.setValue(answer.dateValue, "dateValue")
              if (answer.moneyValue) {
                const money = formAnswer.getLinkedRecord("moneyValue")
                money?.setValue(answer.moneyValue.amount, "amount")
                money?.setValue(answer.moneyValue.currency, "currency")
                money?.setValue(answer.moneyValue.precision, "precision")
              } else {
                formAnswer.setValue(null, "moneyValue")
              }
              if (answer.providerValue) {
                const provider = store.get(answer.providerValue)
                if (provider) {
                  formAnswer.setLinkedRecord(provider, "providerValue")
                }
              } else {
                formAnswer.setValue(null, "providerValue")
              }
            }
          })
          options.optimisticUpdater?.(store, result)
        },
        onCompleted(data) {
          logger.info("Submit induction questions success", { data })
        },
        onError(error) {
          logger.info("Submit induction questions error", { error })
        },
      }).then(() => {
        setUpdatingState({ type: "COMPLETED" })
      })
      setUpdatingState({ type: "UPDATING", promise })
    },
    [track, runMutation, orchestratorContext],
  )
  return [update, updatingStage] as const
}

export function OnboardingOrchestratorContextReset(props: {
  children: React.ReactNode
  onPreviousStep?: () => void
  onNextStep?: () => void
}) {
  const value = useMemo(
    () => ({
      onNextStep: props.onNextStep ?? null,
      onPreviousStep: props.onPreviousStep ?? (() => {}),
      numberOfSteps: 0,
      currentStep: 0,
      lastStep: null,
      listeners: {},
      metadataTracker: new MetadataTracker(),
    }),
    [props.onPreviousStep, props.onNextStep],
  )
  return <Context.Provider value={value}>{props.children}</Context.Provider>
}

export default function OnboardingOrchestrator(
  props: OnboardingOrchestratorProps,
) {
  const renderListenerCalledRef = useRef(false)
  const parentContext = useContext(Context)
  const { value: currentStep, setValue } = useCounter()
  const lastStep = useLastValue(currentStep)
  const children = filterNullsAndFalse(props.steps)
  const [updateAnswers, updateAnswerState] = useUpdateAnswers(
    props.orchestratorContext,
  )
  const value = useMemo((): ContextValue => {
    const listeners = mergeListeners(parentContext.listeners, {
      onNextStep: props.onNextStep,
      onPreviousStep: props.onPreviousStep,
      onStepRendered: props.onStepRendered,
    })
    const isFirstStep = currentStep === 0
    return {
      async onNextStep({
        answers: answersOrAnswer,
        step = 1,
        optimisticUpdater,
        data,
        dontEmitStep,
      }) {
        const answers = Array.isArray(answersOrAnswer)
          ? answersOrAnswer
          : answersOrAnswer == null
            ? []
            : [answersOrAnswer]
        if (answers && answers.length) {
          updateAnswers(answers, { optimisticUpdater })
        }
        setValue(currentStep + step)
        renderListenerCalledRef.current = false
        if (dontEmitStep) {
          return
        }
        listeners.onNextStep?.({
          data,
        })
      },
      onPreviousStep({ data, step = 1 }) {
        if (isFirstStep) {
          parentContext.onPreviousStep?.({
            step,
            data,
          })
          return
        }
        setValue(currentStep - step)
        renderListenerCalledRef.current = false
        listeners.onPreviousStep?.({
          data,
        })
      },
      title: props.title,
      lastStep,
      currentStep: currentStep,
      numberOfSteps: children.length,
      updateAnswerState,
      listeners,
      metadataTracker: parentContext.metadataTracker,
    }
  }, [
    lastStep,
    updateAnswers,
    setValue,
    currentStep,
    children.length,
    parentContext,
    props.title,
    props.onNextStep,
    props.onPreviousStep,
    props.onStepRendered,
    updateAnswerState,
  ])

  useEffect(() => {
    if (renderListenerCalledRef.current) {
      return
    }
    renderListenerCalledRef.current = true
    value.listeners.onStepRendered?.({
      data: value.metadataTracker.getMetadata(),
    })
  }, [value])

  const isOutOfBounds = currentStep >= children.length

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [currentStep])

  // This hook detects if we are out of bounds. This needs to happen as its own hook
  // because steps are conditionally rendered. This means that an `onNextStep` call might
  // change the state (optimistic updates) which then removes the next step, potentially bringing
  // the current step out of bounds.
  // It is a `useLayoutEffect` because we want to run it before the browser paints the next step.
  useLayoutEffect(() => {
    if (!isOutOfBounds) {
      return
    }
    const onEnd = props.onCompleted
    onEnd?.()

    parentContext.onNextStep?.({ data: {}, dontEmitStep: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOutOfBounds])

  if (isOutOfBounds) {
    return null
  }
  return (
    <Context.Provider value={value}>
      {React.cloneElement(children[currentStep], {
        key: currentStep,
      })}
    </Context.Provider>
  )
}

const Context = React.createContext<ContextValue>({
  onPreviousStep: () => {},
  onNextStep: null,
  numberOfSteps: 0,
  currentStep: 0,
  lastStep: null,
  listeners: {},
  metadataTracker: new MetadataTracker(),
})

type OrchestratorEventListenerTrackingProperties = {
  data?: unknown
}

type OrchestratorValue = {
  onNextStep: (
    props?: AnswersInput & OrchestratorEventListenerTrackingProperties,
  ) => void
  onPreviousStep: (
    options?: { step?: number } & OrchestratorEventListenerTrackingProperties,
  ) => void
  lastProgressPct: number
  progressPct: number
  isFirstStep: boolean
  title?: React.ReactNode
}

export function useOrchestrator(
  options: { data?: OnboardingOrchestratorData } = {},
): OrchestratorValue {
  const context = useContext(Context)
  useEffect(() => {
    if (options.data === undefined) {
      return
    }
    return context.metadataTracker.register(options.data)
  }, [context.metadataTracker, options.data])
  const progressPct = (context.currentStep + 1) / (context.numberOfSteps + 1)
  const lastProgressPct =
    context.lastStep == null
      ? 0
      : (context.lastStep + 1) / (context.numberOfSteps + 1)

  return useMemo(
    () => ({
      ...context,
      isFirstStep: context.currentStep === 0,
      lastProgressPct,
      progressPct,
      onNextStep(properties) {
        context.onNextStep?.({
          ...properties,
          data: {
            ...context.metadataTracker.getMetadata(),
            ...(properties?.data ?? null),
          },
        })
      },
      onPreviousStep(properties) {
        context.onPreviousStep?.({
          ...properties,
          data: {
            ...context.metadataTracker.getMetadata(),
            ...(properties?.data ?? null),
          },
        })
      },
    }),
    [context, lastProgressPct, progressPct],
  )
}

export function useOrchestratorWaitForAnswersSaved() {
  const { updateAnswerState } = useContext(Context)
  if (!updateAnswerState) {
    return
  }
  if (updateAnswerState.type === "COMPLETED") {
    return
  }
  throw updateAnswerState.promise
}
