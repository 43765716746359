import { FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import { graphql, useFragment } from "react-relay"

import {
  GlowFlexbox,
  GlowText,
  GlowTooltip,
  GlowIcon,
  GlowCard,
  GlowButton,
  GlowHorizontalDivider,
} from "src/glow"
import { GlowModal } from "src/glow/GlowModal"
import { GBP_ZERO_DECIMAL_PLACES_CONFIG } from "src/intl"
import { CheckedRelayEnum } from "src/utils"

import { NudgeManualModePopUp_household$key } from "./__generated__/NudgeManualModePopUp_household.graphql"

import { NousServiceLevel } from "../__generated__/SettingsPageSavingsMode_household.graphql"

type NudgeaManualModePopUpProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: (serviceLevel: CheckedRelayEnum<NousServiceLevel>) => void
  onCancel: () => void
  household: NudgeManualModePopUp_household$key
  from: CheckedRelayEnum<NousServiceLevel> | null
  updating: boolean
}

export function NudgeManualModePopUp(props: NudgeaManualModePopUpProps) {
  const intl = useIntl()

  const household = useFragment(
    graphql`
      fragment NudgeManualModePopUp_household on Household {
        settingsSavingsPlanWithEstimates: savingsPlan(
          options: {
            estimatesEnabled: true
            skipMortgageWhenNotApplicable: true
          }
        ) {
          totalSavingsAmount {
            amountInGbpPounds
          }
        }
      }
    `,
    props.household,
  )

  return (
    <GlowModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={intl.formatMessage({
        id: "page.settings.view.savingsMode.nudgeaManualModePopUp.title",
        defaultMessage: "Switch to Manual mode?",
      })}
      disableManualClose={props.updating}
    >
      <GlowFlexbox
        direction="column"
        gap="6"
        alignItems="center"
        justifyContent="center"
      >
        <GlowFlexbox direction="column" gap="4" alignItems="center">
          <GlowFlexbox gap="2" alignItems="center">
            <GlowIcon
              name="information_circle_bold"
              className="h-5 w-5 text-gray-300"
            />
            <GlowText fontWeight="bold">
              <FormattedMessage
                id="page.settings.view.savingsMode.nudgeaManualModePopUp.infoText.alert"
                defaultMessage="We won’t secure savings for you in Manual mode"
              />
            </GlowText>
          </GlowFlexbox>

          <GlowText textAlign="center">
            <FormattedMessage
              id="page.settings.view.savingsMode.nudgeaManualModePopUp.infoText.content"
              defaultMessage="On Manual mode we don’t find you any savings or handle the hassle of switching any providers for you."
            />
          </GlowText>
        </GlowFlexbox>

        <GlowCard colour="transparent" className="w-full">
          <GlowFlexbox direction="column" alignItems="center">
            <GlowText fontWeight="bold">
              <FormattedMessage
                id="page.settings.view.savingsMode.nudgeaManualModePopUp.expectedSavings.title"
                defaultMessage="Expected savings:"
              />
            </GlowText>
            <GlowFlexbox gap="2" alignItems="center">
              <GlowText
                size="4xl"
                isDisplay
                textDecoration="strikethrough"
                className="text-angsty-red-400/32"
              >
                <FormattedNumber
                  value={
                    household.settingsSavingsPlanWithEstimates
                      ?.totalSavingsAmount?.amountInGbpPounds ?? 0
                  }
                  {...GBP_ZERO_DECIMAL_PLACES_CONFIG}
                />
              </GlowText>
              <GlowTooltip
                handle={
                  <GlowIcon
                    name="information_circle_bold"
                    className="h-6 w-5 text-gray-400"
                  />
                }
              >
                <FormattedMessage
                  id="page.settings.view.savingsMode.nudgeaManualModePopUp.expectedSavings.tooltip"
                  defaultMessage="Exactly how much you save is down to you. We can't guarantee you'll save with Nous in this saving mode."
                />
              </GlowTooltip>
            </GlowFlexbox>
          </GlowFlexbox>
        </GlowCard>

        <GlowText textAlign="center">
          <FormattedMessage
            id="page.settings.view.savingsMode.nudgeaManualModePopUp.infoText.content2"
            defaultMessage="<strong>We recommend trying Balanced mode:</strong> We’ll always alert you when recommending any changes to your services so you’ll have plenty of time to review them and opt-out."
            values={{
              strong: (chunks) => <strong>{chunks}</strong>,
            }}
          />
        </GlowText>

        <GlowButton
          onClick={() => {
            props.onConfirm("DO_MOST_OF_IT_FOR_ME")
          }}
          isDisabled={props.updating}
          isLoading={props.updating}
          label={
            props.from === "DO_MOST_OF_IT_FOR_ME"
              ? intl.formatMessage({
                  id: "page.settings.view.savingsMode.nudgeaManualModePopUp.confirmButton.label.fromBalanced",
                  defaultMessage: "-> Stay on Balanced mode",
                })
              : intl.formatMessage({
                  id: "page.settings.view.savingsMode.nudgeaManualModePopUp.confirmButton.label.fromFreedom",
                  defaultMessage: "-> Switch to Balanced mode",
                })
          }
          className="w-full"
        />

        <GlowHorizontalDivider />

        <GlowFlexbox
          direction="column"
          gap="4"
          alignItems="center"
          className="w-full"
        >
          <GlowButton
            onClick={() => {
              props.onConfirm("DO_EVERYTHING_MYSELF")
            }}
            isDisabled={props.updating}
            isLoading={props.updating}
            label={intl.formatMessage({
              id: "page.settings.view.savingsMode.nudgeaManualModePopUp.confirmButton.label.switchToManual",
              defaultMessage: "-> Switch to Manual mode",
            })}
            className="w-full"
          />

          <GlowButton
            variant="secondary"
            onClick={() => props.onCancel()}
            isDisabled={props.updating}
            isLoading={props.updating}
            label={intl.formatMessage({
              id: "page.settings.view.savingsMode.nudgeaManualModePopUp.cancelButton.label",
              defaultMessage: "Cancel",
            })}
            className="w-full"
          />
        </GlowFlexbox>
      </GlowFlexbox>
    </GlowModal>
  )
}
