/**
 * @generated SignedSource<<bddef4b72562ace686a432c878bb54d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BankAccountDetailsCard_bankAccount$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BankAccount" | "BankAccountAddOrEditPopup_bankAccount">;
  readonly " $fragmentType": "BankAccountDetailsCard_bankAccount";
};
export type BankAccountDetailsCard_bankAccount$key = {
  readonly " $data"?: BankAccountDetailsCard_bankAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"BankAccountDetailsCard_bankAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BankAccountDetailsCard_bankAccount",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BankAccountAddOrEditPopup_bankAccount"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BankAccount"
    }
  ],
  "type": "BankAccount",
  "abstractKey": null
};

(node as any).hash = "f86cbe0b75d1b47ce58f5e4aa9a20d49";

export default node;
